// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-landing-page-index-js": () => import("../src/Templates/LandingPage/index.js" /* webpackChunkName: "component---src-templates-landing-page-index-js" */),
  "component---src-templates-product-listing-page-index-js": () => import("../src/Templates/ProductListingPage/index.js" /* webpackChunkName: "component---src-templates-product-listing-page-index-js" */),
  "component---src-templates-product-detail-page-index-js": () => import("../src/Templates/ProductDetailPage/index.js" /* webpackChunkName: "component---src-templates-product-detail-page-index-js" */),
  "component---src-templates-category-page-index-js": () => import("../src/Templates/CategoryPage/index.js" /* webpackChunkName: "component---src-templates-category-page-index-js" */),
  "component---src-templates-order-detail-page-index-js": () => import("../src/Templates/OrderDetailPage/index.js" /* webpackChunkName: "component---src-templates-order-detail-page-index-js" */),
  "component---src-templates-profile-page-index-js": () => import("../src/Templates/ProfilePage/index.js" /* webpackChunkName: "component---src-templates-profile-page-index-js" */),
  "component---src-templates-cart-page-index-js": () => import("../src/Templates/CartPage/index.js" /* webpackChunkName: "component---src-templates-cart-page-index-js" */),
  "component---src-templates-checkout-page-index-js": () => import("../src/Templates/CheckoutPage/index.js" /* webpackChunkName: "component---src-templates-checkout-page-index-js" */),
  "component---src-templates-select-cvs-page-index-js": () => import("../src/Templates/SelectCvsPage/index.js" /* webpackChunkName: "component---src-templates-select-cvs-page-index-js" */),
  "component---src-templates-social-login-result-page-index-js": () => import("../src/Templates/SocialLoginResultPage/index.js" /* webpackChunkName: "component---src-templates-social-login-result-page-index-js" */)
}

